import { AUCTIONZIP_HOSTNAME, ENVIRONMENTS, INVALUABLE_HOSTNAME } from 'components/constants';
import { ENV } from 'configs';
import { BIDDER_TYPES_TO_DISPLAY } from 'redux/types';

/**
 * @function formatAcceptedBidder - A helper function to get formatted bidder
 * @param {string} acceptedBidder accepted bidder
 * @param {number} paddle paddle number of accepted bidder
 * @returns {string} formatted accepted bidder and paddle number
 */
export const formatAcceptedBidder = (acceptedBidder: string | undefined, paddle: string | undefined) => {
  const isFloorBidder = acceptedBidder === BIDDER_TYPES_TO_DISPLAY.FLOOR_BIDDER;
  const formattedPaddle = Number(paddle) > 0 && !isFloorBidder ? `(# ${paddle})` : '';
  return acceptedBidder ? `${acceptedBidder} ${formattedPaddle}` : '';
};

/**
 * A helper to return the url for viewing the report of the auction
 * @param {string} catalogId catalog Id
 * @returns {string} url to view auction report
 */
export const getAuctionReportURL = (catalogRef: string) => {
  const subDomain = ENV.DEPLOYMENT_ENVIRONMENT === ENVIRONMENTS.PRODUCTION ? 'aza' : 'stageaza';
  const currentHostname = window.location.hostname;
  const domain = currentHostname.includes(AUCTIONZIP_HOSTNAME) ? AUCTIONZIP_HOSTNAME : INVALUABLE_HOSTNAME;
  return `https://${subDomain}.${domain}/ah/reports/sessionResults/${catalogRef}`;
};

/**
 * A helper to return sold lot message
 * @param {string} currentLotNumber current lot number
 * @returns {string} sold lot message
 */
export const getSoldLotMessage = (currentLotNumber: string) => `Lot ${currentLotNumber} Sold`;

/**
 * A helper to return the hot key appended with CapsLock, shift and CapsLock+shift
 * @param {string} key hot key
 * @returns {string} comma separated keys appended with CapsLock, shift and CapsLock+shift
 */
export const appendShiftCapsLockToHotKeys = (key: string) =>
  `${key}, CapsLock+${key}, shift+${key}, CapsLock+shift+${key}`;

/**
 * A helper to return true if the URL is valid
 * @param {string} URL to check whether it is valid or not
 * @returns {string} returns true if the URL is valid otherwise returns false
 */
export const isValidURL = (url: string) => {
  const regexp = /(http|https):\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/g;
  return regexp.test(url);
};
