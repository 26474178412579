import { ENV } from 'configs';

import { ICatalogResponse, ILotsResponse, ICatalogLotsData } from './types';

/**
 * Returns catalog data REST end-point.
 * @param catalogRef
 */
export const getCatalogURL = (catalogRef: string): string =>
  `${ENV.RESTFUL_BASE_URL}/api/auctions/consoleCatalogInfo/catalogRef/${catalogRef}`;

/**
 * Returns catalog lots data REST end-point.
 * @param catalogRef
 * @param startingLotID
 * @param numLots
 */
export const getLotURL = (catalogRef: string, startingLotID: number, numLots: number): string =>
  `${ENV.RESTFUL_BASE_URL}/app/consoleLotInfo/catalogRef/${catalogRef}/lotID/${startingLotID}?numLots=${numLots}`;

/**
 * Merges catalog data and lot data into a single object.
 * @param catalogData
 * @param lotsData
 * @return standard response object
 */
export const mergeCatalogAndLotData = (catalogData: ICatalogResponse, lotsData: ILotsResponse): ICatalogLotsData => {
  return {
    title: catalogData.title,
    currencyCode: catalogData.currencyCode,
    lots: lotsData.lots.map(lot => {
      return {
        lotID: lot.lotID,
        lotRef: lot.lotRef,
        title: lot.title,
        description: lot.description,
        lotNumber: lot.lotNumber,
        estimateLow: lot.estimateLow,
        estimateHigh: lot.estimateHigh,
        primaryImage: {
          thumbURL: lot.primaryImage?.thumbURL,
        },
      };
    }),
  };
};
