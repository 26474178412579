/**
 * @function getSubStringBetweenStartAndEndToken - A utility function that returns the substring of a given string.
 * @param {string} message string to be substring
 * @param {string} startToken start token from where substring starts
 * @param {string} endToken end token to where substring ends
 * @returns {string} substring between the start and end tokens
 */
export const getSubStringBetweenStartAndEndToken = (
  message: string,
  startToken: string,
  endToken: string | undefined = undefined,
) => {
  const startIndex = message.indexOf(startToken) + startToken.length;
  const endIndex = endToken === undefined ? undefined : message.indexOf(endToken, startIndex);

  return message.substring(startIndex, endIndex);
};

/**
 * @function isValueNull - A utility function that returns true if a value is empty
 * @param {any} value value to be checked
 */
export const isValueNull = (value: any): boolean => {
  if (!value || value === 'null' || value === '0') {
    return true;
  }
  return false;
};

/**
 * A utility function that returns an Error object created using the given name and message
 * @param {string} message - Error message
 * @param {string} [name] - Error name
 * @returns {Error} Returns an instance of Error
 */
export const formatError = (message: string, name?: string): Error => {
  const error = new Error(message);
  if (name) error.name = name;
  return error;
};
