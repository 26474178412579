import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';

import { STATIC_MESSAGES, HOT_KEYS_MAP } from 'components/constants';
import { mutateAsk } from 'redux/middlewares/live-server/actions';
import { brokerActions } from 'redux/slices/broker';
import { selectAskPriceOnServer, selectAskPrice, selectCurrentBid } from 'redux/slices/broker/selectors';

import { AskAndIncrementNumericField } from '../ask-and-increment-numeric-field';
import { removeCommaFromValue } from '../helpers';

import styles from './ask.module.scss';

export function Ask() {
  const dispatch = useDispatch();
  const currentBid = useSelector(selectCurrentBid);
  const previousAskPriceStr = useSelector(selectAskPriceOnServer).toString();
  const updatedAskPrice = useSelector(selectAskPrice);

  const askAmountLength = updatedAskPrice.toString().length;

  const handleUpdateAskAmount = (event: React.FormEvent<HTMLInputElement>) => {
    const updatedAskValue = removeCommaFromValue(event?.currentTarget?.value);

    if (updatedAskValue === '') {
      dispatch(brokerActions.updateAskPrice(previousAskPriceStr));
    } else if (+updatedAskValue <= +currentBid) {
      dispatch(brokerActions.displayCustomMessage({ message: STATIC_MESSAGES.ASK_CHANGE_BID_ERROR, isAlert: true }));
      dispatch(brokerActions.updateAskPrice(previousAskPriceStr));
    } else if (previousAskPriceStr !== updatedAskValue) {
      dispatch(mutateAsk(+updatedAskValue));
    }
  };

  const onAskChange = (updatedAsk: string) => {
    dispatch(brokerActions.updateAskPrice(updatedAsk));
  };

  const editableFieldClasses = classNames(styles['editable-text'], {
    [styles['editable-text-md']]: askAmountLength > 5 && askAmountLength <= 8,
    [styles['editable-text-lg']]: askAmountLength > 8,
  });

  return (
    <div className={styles['ask']}>
      <span>Ask:</span>
      <span className={editableFieldClasses}>
        <AskAndIncrementNumericField
          value={updatedAskPrice}
          hotkeysToEnableEditMode={HOT_KEYS_MAP.EDIT_ASK_FIELD}
          onChange={onAskChange}
          onBlur={handleUpdateAskAmount}
        />
      </span>
    </div>
  );
}
