import cn from 'classnames';
import { useSelector, useDispatch } from 'react-redux';

import { HOT_KEYS_MAP, STATIC_MESSAGES } from 'components/constants';
import { BID_TYPES } from 'components/right-panel/types';
import { useHotKeysOnKeyup } from 'hooks/use-hot-keys-on-keyup';
import { mutateBidAccept } from 'redux/middlewares/live-server/actions';
import { formatCurrency } from 'redux/middlewares/live-server/helpers';
import { brokerActions } from 'redux/slices/broker';
import {
  selectApprovalId,
  selectSubmittedBid,
  selectSubmittedBidPaddle,
  selectSubmittedBidType,
  selectAskPriceOnServer,
  selectCurrentAuctionState,
} from 'redux/slices/broker/selectors';
import { AUCTION_STATES } from 'redux/slices/broker/types';
import { selectCurrencyCode } from 'redux/slices/catalog/selectors';
import { isValueNull } from 'utils';

import { getDynamicFontSizeForBidButtons } from '../helpers';
import { isInternetBidEnabled } from './helpers';

import styles from './internet-bid-btn.module.scss';

export function InternetBidBtn() {
  const dispatch = useDispatch();
  const currencyCode = useSelector(selectCurrencyCode);
  const askPrice = useSelector(selectAskPriceOnServer);
  const approvalId = useSelector(selectApprovalId);
  const submittedBid = useSelector(selectSubmittedBid);
  const submittedBidPaddle = useSelector(selectSubmittedBidPaddle);
  const submittedBidType = useSelector(selectSubmittedBidType);
  const auctionState = useSelector(selectCurrentAuctionState);

  const isInternetBidBtnEnabled = isInternetBidEnabled(submittedBid, submittedBidType, askPrice, approvalId);
  const internetBidBtnClasses = cn(styles['internet-bid-btn'], {
    [styles['on']]: isInternetBidBtnEnabled,
  });

  const submittedBidAmount = formatCurrency(submittedBid);

  const onInternetBidBtnClick = () => {
    if (isInternetBidBtnEnabled) {
      if (auctionState === AUCTION_STATES.IN_PROGRESS) {
        dispatch(mutateBidAccept(submittedBidType as BID_TYPES, submittedBid));
      } else {
        dispatch(brokerActions.displayCustomMessage({ message: STATIC_MESSAGES.START_AUCTION_FIRST }));
      }
    }
  };

  const onShiftKeyPress = (event: KeyboardEvent) => {
    if (event.key === 'Shift') {
      onInternetBidBtnClick();
    }
  };

  useHotKeysOnKeyup(HOT_KEYS_MAP.CHECK_ALL_THE_AVAILABLE_KEYS, onShiftKeyPress, [
    isInternetBidBtnEnabled,
    auctionState,
    submittedBid,
  ]);

  useHotKeysOnKeyup(HOT_KEYS_MAP.ACCEPT_INTERNET_BID, onInternetBidBtnClick, [
    isInternetBidBtnEnabled,
    auctionState,
    submittedBid,
  ]);

  return (
    <button onClick={onInternetBidBtnClick} className={internetBidBtnClasses}>
      <div className={styles['bid-amount']}>
        <div className={styles['currency-amount-wrapper']}>
          <span className={styles['currency']}>{currencyCode}</span>
          <span
            className={styles['amount']}
            style={{ fontSize: getDynamicFontSizeForBidButtons(submittedBidAmount?.length) }}
          >
            {submittedBidAmount}
          </span>
        </div>
        {!isValueNull(submittedBidPaddle) && (
          <span className={styles['bid-paddle']}>Paddle # {submittedBidPaddle}</span>
        )}
      </div>
    </button>
  );
}
